export const AboutHard = [
  {
    id: 1,
    title: "Ցանկացած բարդության նախագծերի համար",
    text: "Փոքր կոնստրուկցիաներից մինչև խոշոր",
  },
  {
    id: 2,
    title: "Արագ Սպասարկում",
    text: "Կարճ ժամկետներում բարձր որակ",
  },
  {
    id: 3,
    title: "Ճկուն վճարում",
    text: "Վճարման հարմար տարբերակներ ֆիզիկական և իրավաբանական անձանց համար",
  },
  {
    id: 4,
    title: "Առաջինը որպես էդպիսին",
    text: "Տարատեսակ ծառայություններ",
  },
];