import avaz from "../../images/sliderPng/shin6.avif";
import avaz3 from "../../images/sliderPng/shin8.avif";
import avaz1 from "../../images/sliderPng/shin1.avif";
// import avaz2 from "../../images/sliderPng/shin5.avif";
import avaz4 from "../../images/sliderPng/shin3.avif";
import avaz5 from "../../images/sliderPng/shin7.avif";
import avaz2 from "../../images/sliderPng/shin9.avif";

export const hardHomeSlider = [
  avaz,
  avaz1,
  avaz2,
  avaz3,
  avaz4,
  avaz5,
]