import img4 from "../../images/png/ServicesImg/img4.jpg";
import img5 from "../../images/png/homePageImg5jpg.jpg";
import {imgs} from "../../images/img.js";


export const ServiceItems = [
  {
    id: 1,
    title: "Մոնտաժ / Դեմոնտաժ",
    image: imgs.aboutImg,
    text: "Ապահովելով անվտանգությունը՝ կատարում ենք ցանկացած տեսակի հավաքում և ապամոնտաժում",
    text2:
      "Մենք գերազանցում ենք հավաքման և ապամոնտաժման տարբեր առաջադրանքների անխափան կատարումը արդյունաբերության լայն տեսականիում: Մեր փորձառու թիմը հագեցած է փորձով և ճշգրտությամբ՝ ցանկացած նախագիծ արդյունավետ և անվտանգ վարելու համար: Որպես մեր գլխավոր առաջնահերթություններ՝ սկսած մանրակրկիտ պլանավորումից և խիստ անվտանգության արձանագրություններից մինչև մանրակրկիտ ուշադրություն յուրաքանչյուր փուլում, մենք երաշխավորում ենք, որ հավաքման և ապամոնտաժման յուրաքանչյուր գործողություն համապատասխանում է որակի և հուսալիության ամենաբարձր չափանիշներին, ապահովելով ձեր նախագծերի ավարտը սահուն և առավելագույն խնամքով:",
    text3:
      "«Անվտանգ ապամոնտաժում, ճշգրիտ հավաքում՝ անվտանգություն ամեն քայլափոխի»",
  },
  {
    id: 2,
    title: "Քանդման Աշխատանքներ",
    image: imgs.services.servicesImg2,
    text: "Կատարում ենք քանդման աշխատանքներ՝ պահպանելով անվտանգության կանոնները.",
    text2:
      "Ներկայումս մենք զբաղվում ենք քանդման աշխատանքներով՝ օգտագործելով մեր փորձը և առաջադեմ սարքավորումները՝ տարբեր չափերի և բարդության կառույցներ արդյունավետ ապամոնտաժելու համար: Մեր հմուտ թիմը մանրակրկիտ պլանավորում և իրականացնում է յուրաքանչյուր նախագիծ՝ ապահովելով ճշգրտություն և մանրակրկիտություն յուրաքանչյուր փուլում: Լինի դա կառուցվածքային քանդում թե ներքին քանդում: մենք կատարում ենք յուրաքանչյուր առաջադրանք պրոֆեսիոնալիզմով և մանրուքների նկատմամբ մեր հավատարմությունը մղում է մեզ ապահովելու արդյունքներ, որոնք համապատասխանում են կամ գերազանցում են հաճախորդների սպասելիքները, որոնք ապահովում են ձեր նախագծի սահուն առաջընթացը:",
    text3: "«Արդյունավետ քանդում յուրաքանչյուր նախագծի համար»",
  },
  {
    id: 3,
    title: "Աղբի եվ Շինաղբի Տեղափոխում",
    text: "Աղբը և աղբահանությունը կարևոր են մաքրության և հիգիենայի պահպանման համար .",
    image: imgs.services.servicesImg1,
    text2:
      "Մենք արդյունավետորեն զբաղվում ենք աղբի և շինարարական թափոնների հեռացմամբ: Մեր ծառայությունները նախատեսված են բավարարելու բնակելի, առևտրային և արդյունաբերական հաճախորդների կարիքները: Մեր թիմով և առաջադեմ սարքավորումներով մենք ապահովում ենք թափոնների արագ և հուսալի հեռացում: Անկախ նրանից, թե դա վերանորոգման բեկորներ են, խոշոր իրեր կամ ամենօրյա աղբ, մենք այդ ամենը կառավարում ենք պրոֆեսիոնալ և արդյունավետ: Մենք առաջնահերթություն ենք տալիս վերամշակման և պատշաճ հեռացման մեթոդներին՝ աջակցելու թափոնների կայուն կառավարմանը: Դուք կարող եք ապավինել մեզ հուսալի ծառայության համար, որը ձեր գույքը մաքուր է պահում և համապատասխանում է թափոնների կանոններին:",
    text3: "«Թափոնների մաքրում, տարածքների կոկիկ պահպանում»",
  },
  {
    id: 4,
    title: "Կապիտալ Վերանորոգում",
    text: "Կատարում ենք ցանկացած տեսակի կապիտալ վերանորոգման աշխատանքներ.",
    image: imgs.services.servicesImg3,
    text2:
      "Մենք կենտրոնացած ենք ամբողջական կապիտալ վերանորոգման վրա, որը նախատեսված է բնակելի, առևտրային և արդյունաբերական օբյեկտների տարբեր կարիքների համար: Մեր թիմը հմուտ է կառուցվածքային բարելավումներ, մեխանիկական թարմացումներ և էսթետիկ բարելավումներ պարունակող նախագծերի մշակման գործում: Սկզբնական գնահատման և պլանավորման փուլերից մինչև իրականացում և վերջնականացում Մենք առաջնահերթություն ենք տալիս մանրակրկիտ ուշադրությանը և բարձրորակ աշխատանքին: Մեր նպատակն է բարելավել ձեր գույքի ֆունկցիոնալությունը, երկարակեցությունը և տեսողական գրավչությունը՝ ապահովելով արդյունքներ, որոնք գերազանցում են ձեր սպասելիքները ձեր կապիտալ վերանորոգման նախագիծը:",
    text3: "«Ընդլայնված հատկություններ ​​կապիտալ վերանորոգմամբ»",
  },
  {
    id: 5,
    title: "Սպիտակ Ավազ",
    text: "Ձեզ ենք ներկայացնում բարձրակարգ որակ.",
    image: imgs.homeImgss.homeImg5,
    text2:
      "Մենք մասնագիտացած ենք բարձրորակ սպիտակ ավազի արդյունավետ առաքման մեջ: Մեր պարզեցված գործընթացը ապահովում է արագ և հուսալի սպասարկում՝ բավարարելով շինարարության, կանաչապատման կամ հանգստի նախագծերի ձեր կարիքները: Ապահովեք մեզ հուսալի առաքման համար, որը համապատասխանում է ձեր ժամանակացույցին և որակի ակնկալիքներին:",
    text3: "«Բարձրորակ սպիտակ ավազի հուսալի առաքում»",
  },
  {
    id: 6,
    title: "Բանվորական ՈՒժ",
    text: "Ձեզ տրամադրում ենք բանվորական ուժ",
    image: imgs.services.servicesImg4,
    text2:
      "Մենք առաջարկում ենք աշխատուժի հուսալի լուծումներ, որոնք հարմարեցված են տարբեր ոլորտների կադրերի կարիքները բավարարելու համար: Մեր հմուտ աշխատուժը հագեցած է փորձով և փորձով, որն անհրաժեշտ է աջակցելու ծրագրերին՝ սկսած շինարարությունից և արտադրությունից մինչև հյուրասիրություն և միջոցառումներ: Անկախ նրանից, թե դուք ժամանակավոր աշխատուժ եք պահանջում հատուկ առաջադրանքների համար, թե՝ Ընթացիկ նախագծերի համար նվիրված թիմ, մենք ապահովում ենք աշխատուժի արագ տեղակայում և արդյունավետ կառավարում Մեր հանձնառությունն է ապահովել գրագետ և աշխատասեր անձնակազմ, որը նպաստում է ձեր գործունեության հաջողությանը և արդյունավետությանը:",
    text3: "«Վստահելի աշխատուժ յուրաքանչյուր աշխատանքի համար»",
  },
];
